<template>
  <div>
    <div class="oa-work-box border">
      <span>
        <el-button class="oa-button" size="mini" @click="add">
          <font-awesome-icon fas icon="plus"></font-awesome-icon>&nbsp;添加
        </el-button>
        <el-button class="oa-button" size="mini" @click="update">
          <font-awesome-icon fas icon="edit"></font-awesome-icon>&nbsp;修改
        </el-button>
        <el-button class="oa-button oa-button-danger" size="mini" @click="del">
          <font-awesome-icon fas icon="trash"></font-awesome-icon>&nbsp;删除
        </el-button>
      </span>
      <span>
        <el-input v-model="searchOption.key" class="search-input" size="mini" placeholder="请输入名称搜索">
        </el-input>
        <el-button type="primary" size="mini" @click="search">
          <font-awesome-icon fas icon="search"></font-awesome-icon>&nbsp;查询
        </el-button>
      </span>
    </div>
    <!-- 主体内容 -->
    <el-table border stripe :data="list" @selection-change="selectionChange" size="mini" class="oa-table">
      <el-table-column width="50" align="center" type="index" label="#"></el-table-column>
      <el-table-column type="selection" min-width="50" header-align="center" align="center">
      </el-table-column>
      <el-table-column prop="Name" label="名称"></el-table-column>
      <el-table-column prop="UserName" label="账号"></el-table-column>
      <el-table-column prop="Role" label="角色">
        <template slot-scope="scope">
          <el-tag>{{ roles[scope.row.Role].label }}</el-tag>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background layout="total, sizes, prev, pager, next, jumper" :current-page="pageIndex"
        :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="total" @size-change="pageSizeChange"
        @current-change="pageIndexChange">
      </el-pagination>
  </div>
</template>

<script>
import API from '../../apis/ticket-api'
import { USER, USER_FORM } from '../../router/base-router'
import { ROLE } from '../../assets/js/const'

export default {
  name: USER.name,
  data () {
    return {
      roles: ROLE, // 角色
      searchOption: {
        key: '', // 搜索条件（关键字）
        type: -1
      },
      list: [], // 列表
      total: 1, // 总数据量
      pageIndex: 1, // 页码
      pageSize: 50, // 页数
      selectionList: [] // 选中的人员列表
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => vm.get())
  },
  methods: {
    get () {
      this.loading = true
      this.axios.get(`${API.USER}/${this.pageIndex}/${this.pageSize}`, {
        params: this.searchOption
      }).then(response => {
        this.total = response.Total
        this.list = response.Items
        this.loading = false
      })
    },
    pageSizeChange (value) {
      this.pageSize = value
      this.$nextTick(() => this.get())
    },
    pageIndexChange (value) {
      this.pageIndex = value
      this.$nextTick(() => this.get())
    },
    search () {
      this.pageIndex = 1
      this.$nextTick(() => this.get())
    },
    selectionChange (rows) {
      this.selectionList = rows
    },
    add () {
      var entity = { isAdd: true }
      this.toFormPage(entity)
    },
    update () {
      if (this.checkHadSelection()) {
        const entity = this.selectionList[0]
        this.toFormPage(entity)
      }
    },
    del (entity) {
      if (this.checkHadSelection()) {
        this.$confirm('确认要删除选中人员资料?删除后不可恢复，请谨慎操作！', '温馨提示', {
          type: 'warning',
          cancelButtonText: '放弃操作'
        }).then(() => {
          const ids = this.selectionList.map(s => s.id)
          this.axios.post(API.USER + '/Batch/IsDeleted', ids).then(response => {
            if (response.data.status) {
              this.get()
            }
          })
        })
      }
    },
    checkHadSelection () {
      if (this.selectionList.length < 1) {
        this.$message.error('请先选择要操作数据')
        return false
      }
      return true
    },
    toFormPage (params) {
      this.$router.push({ ...USER_FORM, params: params })
    }
  },
  created () {
    this.get()
  }
}
</script>

<style lang="scss" scoped>
/deep/ .oa-work-box {
  .search-input {
    width: 200px;
    margin-right: .75rem;
  }

  .type-select {
    width: 100px;
    margin-right: .75rem;
  }

}
</style>
